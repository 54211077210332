import { useFormik } from "formik"
import * as yup from "yup"
import React, { useContext } from "react"
import { message } from "antd"
import { useTranslation } from "react-i18next"
import Head from "next/head"
import { signInWithEmailAndPassword, theme } from "@project/shared"
import styled from "styled-components"
import * as Sentry from "@sentry/node"
import { CloseCircleFilled } from "@ant-design/icons"
import RestrictedRoute from "../../withRestrictedRoute"
import { InputField, Button } from "../../components"
import { HtmlType } from "../../constants"
import { AuthContext } from "../../utils"
import { AdminType } from "../../utils/enums/adminType"

interface LoginType {
  email: string
  password: string
}

const Container = styled.div`
  background: ${theme.background.colorSecondary1};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .login {
    ${theme.typography.heading}
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  .login-container {
    width: 650px;
    border: solid 1px ${theme.border.colorSecondary4};
    box-shadow: 0px 4px 4px ${theme.shadow.colorPrimary};
    padding: 45px;
  }
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text-wrapper {
    margin-bottom: 30px;
  }
  .logo {
    margin-bottom: 30px;
  }
`

const LoginPage: React.FC = () => {
  const { t } = useTranslation()
  const { setUser, setIsAdmin, setIsSuperAdmin } = useContext(AuthContext)
  const [loading, setLoading] = React.useState(false)

  const handleLoginFormSubmit = () => {
    handleLogin()
  }

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t("error_email"))
      .required(t("please_enter_e-mail_address")),
    password: yup.string().required(t("Please enter password")),
  })

  const formik = useFormik<LoginType>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: handleLoginFormSubmit,
  })

  const checkLoggedInUser = (payload: any) => {
    if (
      payload["cognito:groups"]?.[0] === AdminType.SUPER_ADMIN ||
      payload["cognito:groups"]?.[0] === AdminType.ADMIN_USER
    ) {
      setUser(payload)
      if (payload["cognito:groups"]?.[0] === AdminType.SUPER_ADMIN) {
        setIsSuperAdmin(true)
      } else {
        setIsAdmin(true)
      }
      message.success({
        content: t("logged_in_successfully"),
        key: "1",
      })
    } else {
      setUser(null)
      localStorage.clear()
      message.error({
        key: "01",
        icon: <CloseCircleFilled onClick={() => message.destroy("01")} />,
        content: t("Unauthorized user"),
      })
    }
  }

  const onSuccess = (data: { idToken: { payload: any } }) => {
    if (data?.idToken?.payload) {
      checkLoggedInUser(data?.idToken?.payload)
      setLoading(false)
    }
  }

  const onFailure = (error: { message: string }) => {
    setUser(null)
    setLoading(false)
    message.error({
      content: t(
        error?.message || "An error has occurred. Please try again later."
      ),
      key: "1",
      icon: <CloseCircleFilled onClick={() => message.destroy("1")} />,
    })
    Sentry.captureException(error)
    setLoading(false)
  }

  const handleLogin = async () => {
    setLoading(true)
    await signInWithEmailAndPassword(
      formik.values.email,
      formik.values.password,
      onSuccess,
      onFailure
    )
  }

  return (
    <>
      <Head>
        <title>{t("login")}</title>
      </Head>
      <Container>
        {/* <div className={"logo"}>
          <img
            width={"240px"}
            height={"79px"}
            src={"assets/icons/logo.svg"}
            alt={"Logo"}
          />
        </div> */}
        <h1>顔認証決済システム管理サイト（役場用）</h1>
        <form onSubmit={formik.handleSubmit}>
          <div className={"login-container"}>
            <span className={"login"}>{t("login")}</span>
            <div className={"text-wrapper"}>
              <InputField
                label={t("email_address")}
                required
                name={"email"}
                onChange={formik.handleChange}
                value={formik.values.email}
                error={
                  formik.touched.email &&
                  formik.errors.email &&
                  t(formik.errors.email)
                }
                inputClassName={"email"}
              />
            </div>
            <div className={"text-wrapper"}>
              <InputField
                type={"password"}
                label={t("password")}
                required
                name={"password"}
                onChange={formik.handleChange}
                value={formik.values.password}
                error={
                  formik.touched.password &&
                  formik.errors.password &&
                  t(formik.errors.password)
                }
                inputClassName={"password"}
              />
            </div>
            <div className={"button"}>
              <Button
                label={"ログイン"}
                width={"215px"}
                htmlType={HtmlType.SUBMIT}
                loading={loading}
                className={"buttonDefault"}
              />
            </div>
          </div>
        </form>
      </Container>
    </>
  )
}

export default RestrictedRoute(LoginPage)
